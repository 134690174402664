import { KeyValue } from '@angular/common';

export function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function fromEntries(iterable) {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {});
}

export function toList(x: any): any[] {
  switch (true) {
    case x instanceof Array:
      return x;
    case typeof x === 'undefined':
      return [];
    default:
      return [x];
  }
}

export function defaults(...args) {
  return args.reverse().reduce((acc, obj) => ({...acc, ...obj}), {});
}

export function originalOrder(a: KeyValue<string, string>, b: KeyValue<string, string>): number {
  return 0;
}

export function rangeArray(start, end): number[] {
  return [...Array(end - start + 1).keys()].map((x) => x + start);
}

export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}

export function isDefined(val: any) {
  return val !== null && val !== undefined;
}

export const isObject = (value) => {
  return !!(value && typeof value === 'object' && !Array.isArray(value));
};

export const isArray = (value) => {
  return !!(value && Array.isArray(value));
};

export const isString = (value) => {
  return !!(value && typeof value === 'string');
};

export const isNumber = (value) => {
  return !!(value && typeof value === 'number');
};

export const isBoolean = (value) => {
  return !!(value && typeof value === 'boolean');
};

export function decodeHexStr(str: string) {
  const byteArray = new Uint8Array(str.match(/[\da-f]{2}/gi).map((h: string) => parseInt(h, 16)));
  const decodedString = new TextDecoder().decode(byteArray);
  return JSON.parse(decodedString);
}

export function isHexString(str: string): boolean {
  return /^[0-9a-fA-F]+$/i.test(str);
}
