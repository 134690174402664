import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, first, share, switchMap, tap } from 'rxjs/operators';
import { CacheService } from '../../cache/cache.service';
import { Router, UrlSerializer } from '@angular/router';
import { ApiProviderInterface } from '../interfaces/api-provider-interface';
import { REQUEST } from '../../../../../express.tokens';
import { PlatformService } from '../../platform.service';
import { inject } from '@angular/core';

export class ApiProviderConfig {
  /**
   * Default options for all requests sent from current instance
   */
  defaultOptions: any;

  /**
   * Api host
   */
  host: string;

  /**
   * Uses when url part needs to be resolved for every request
   *
   * For example language in requests to CMS: https://someapi.com/${_urlResolutionFn result}/banners/list
   */
  urlResolutionFn?: () => string | Observable<string>;

  /**
   * Function that will we called in case of error
   */
  errorHandler?: (error: HttpErrorResponse) => void;
}

export class ApiProvider implements ApiProviderInterface {

  /**
   * Inject global HttpClient
   */
  private _http: HttpClient = inject(HttpClient);

  /**
   * Inject global CacheService
   */
  private _cache: CacheService = inject(CacheService);

  /**
   * Inject global Router
   */
  private _router: Router = inject(Router);

  /**
   * Inject global UrlSerializer
   */
  private _urlSerializer: UrlSerializer = inject(UrlSerializer);

  private _platform: PlatformService = inject(PlatformService);

  private _customHeadersList: any[] = [];

  constructor(
    private _config: ApiProviderConfig
  ) {
    this._config.errorHandler = this._config.errorHandler || ((e) => {});
    this._config.urlResolutionFn = this._config.urlResolutionFn || (() => '');
  }

  /**
   * Get ApiProvider custom Headers List
   */
  public get customHeadersList(): any[] {
    return this._customHeadersList;
  }

  /**
   * DELETE request base implementation
   *
   * @param url
   * @param data
   * @param options
   */
  delete(url: string, data?: object, options: object = {}): Observable<any> {
    const opts = {
      ...options,
      body: data,
    };

    return this.request('DELETE', url, opts);
  }

  /**
   * GET request base implementation
   *
   * @param url
   * @param queryParams
   * @param options
   * @param partsUrl
   */
  get(url: string, queryParams?: object, options: object = {}, partsUrl?: string[]): Observable<any> {
    const opts = {
      ...options,
      params: queryParams,
    };

    if (partsUrl && partsUrl.length) {
      url = url + '/' + partsUrl.join('/');
    }

    return this.request('GET', url, opts);
  }

  /**
   * GET request implementation with cache
   *
   * @param url
   * @param queryParams
   * @param options
   */
  getCached(url: string, queryParams?: object, options?: object): Observable<any> {
    const opts = {
      ...options,
      params: queryParams,
    };

    let urlResolutionResult = this._config.urlResolutionFn();
    /**
     * For async url resolution
     */
    if (!(urlResolutionResult instanceof Observable)) {
      urlResolutionResult = of(urlResolutionResult);
    }
    return urlResolutionResult.pipe(
      first(),
      switchMap(resolvedUrl => {
        const cacheKey = this._resolveCacheKey(this._config.host + resolvedUrl + url, opts);
        return this._cache.has(cacheKey)
          ? this._cache.get(cacheKey)
          : this.request('GET', url, opts).pipe(
            tap(res => this._cache.set(cacheKey, res))
          );
      })
    );
  }

  /**
   * PATCH request base implementation
   *
   * @param url
   * @param data
   * @param options
   */
  patch(url: string, data?: object, options: object = {}): Observable<any> {
    const opts = {
      ...options,
      body: data,
    };

    return this.request('PATCH', url, opts);
  }

  /**
   * POST request base implementation
   *
   * @param url
   * @param data
   * @param options
   */
  post(url: string, data?: object | string, options: object = {}): Observable<any> {
    const opts = {
      ...options,
      body: data,
    };

    return this.request('POST', url, opts);
  }

  /**
   * PUT request base implementation
   *
   * @param url
   * @param data
   * @param options
   */
  put(url: string, data?: object, options: object = {}): Observable<any> {
    const opts = {
      ...options,
      body: data,
    };

    return this.request('PUT', url, opts);
  }

  /**
   *  Universal request base implementation
   *
   * @param method
   * @param url
   * @param options
   */
  request(method: string, url: string, options: any): Observable<any> {
    this._addCustomHeaders();
    // this._serverHeaders();

    const opts = {
      ...this._config.defaultOptions,
      ...options
    };

    let urlResolutionResult = this._config.urlResolutionFn();

    /**
     * For async url resolution
     */
    if (!(urlResolutionResult instanceof Observable)) {
      urlResolutionResult = of(urlResolutionResult);
    }

    return urlResolutionResult.pipe(
      first(),
      switchMap(resolutionResult => this._http.request(method, this._config.host + resolutionResult + url, opts)),
      share(),
      catchError(error => {
        this.onError(error);

        return throwError(error);
      })
    );
  }

  /**
   * Base error handling implementation
   *
   * @param error
   */
  onError(error: HttpErrorResponse): void {
    this._config.errorHandler(error);
  }


  /**
   * Create cache key
   *
   * @param url
   * @param options
   * @private
   */
  private _resolveCacheKey(url, options) {
    const urlTree = this._router.createUrlTree([url], {
      queryParams: options.params
    });

    return this._urlSerializer.serialize(urlTree);
  }

  /**
   * Check _customHeadersList and add Custom headers
   */
  private _addCustomHeaders() {
    this._customHeadersList.forEach(e => {
      if (e.val) {
        this._config.defaultOptions.headers = this._config.defaultOptions.headers.set(e.key, e.val);
      }
    });
  }

  /**
   * Resolve headers for server
   *  - set CF headers IPCountry
   * @param headers
   */
  private _serverHeaders() {
    if (!this._platform.isBrowser) {
      if (inject(REQUEST).headers['cf-ipcountry']) {
        this._customHeadersList.push({
          key: 'UC',
          val: inject(REQUEST).headers['cf-ipcountry']
        });
      }
    }
  }

}
