import { Injectable } from '@angular/core';
import { ApiProvider } from './helpers/api-provider';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { environment } from '../../../../environments/environment';
import {PlatformService} from '../platform.service';
import {catchError, timeout} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SsApiService {

  /**
   * For API communication
   */
  private _api: ApiProvider = new ApiProvider(
    {
      defaultOptions: {
        headers: new HttpHeaders({
          Accept: 'application/vnd.softswiss.v1+json',
          'Content-Type': 'application/json'
        }),
        withCredentials: true
      },
      host: environment.ss_api_host,
      errorHandler: (error: HttpErrorResponse) => {
        if (error.status === 401 && this._platform.isBrowser) {
          window.location.href = '/login';
        }
      }
    }
  );

  constructor(
    private _platform: PlatformService
  ) { }

  /**
   * POST /users/sign_in
   *
   * @param data
   */
  usersSignIn(data: object): Observable<any> {
    return this._api.post('/users/sign_in', data);
  }

  /**
   * POST /users
   *
   * @param data
   * @param params
   */
  usersSignUp(data: object, params: object = {}): Observable<any> {
    return this._api.post('/users', data, {
      params
    });
  }

  /**
   * PUT /users
   *
   * @param data
   * @param params
   */
  putUsers(data: object, params: object = {}): Observable<any> {
    return this._api.put('/users', data, {
      params
    });
  }

  /**
   * POST /users/password
   *
   * @param data
   */
  postUsersPassword(data: object): Observable<any> {
    return this._api.post('/users/password', data);
  }

  /**
   * PUT /users/password
   *
   * @param data
   */
  putUsersPassword(data: object): Observable<any> {
    return this._api.put('/users/password', data);
  }

  /**
   * POST /users/unlock
   *
   * @param data
   */
  postUsersUnlock(data: object): Observable<any> {
    return this._api.post('/users/unlock', data);
  }

  /**
   * GET /users/confirmation
   *
   * @param params
   */
  usersUnlock(params: object): Observable<any> {
    return this._api.get('/users/unlock', params);
  }

  /**
   * POST /users/confirmation
   *
   * @param data
   */
  postUsersConfirmation(data: object): Observable<any> {
    return this._api.post('/users/confirmation', data);
  }

  /**
   * GET /users/confirmation
   *
   * @param params
   */
  usersConfirmation(params: object): Observable<any> {
    return this._api.get('/users/confirmation', params);
  }

  /**
   * DELETE /users/sign_out
   */
  usersSignOut(): Observable<any> {
    return this._api.delete('/users/sign_out');
  }

  /**
   * GET /player/accounts
   */
  playerAccounts(params: object = {}): Observable<any> {
    return this._api.get('/player/accounts', params).pipe(
      timeout(15000),
      catchError(() => {
        return of([]);
      })
    );
  }

  /**
   * GET /player
   */
  player(params: object = {}): Observable<any> {
    return this._api.get('/player', params).pipe(
      timeout(15000),
      catchError(() => {
        return of({language: 'en'});
      })
    );
  }

  /**
   * GET /player/stats
   */
  playerStats(params: object = {}): Observable<any> {
    return this._api.get('/player/stats', params);
  }

  /**
   * PATCH /player
   *
   * @param data
   */
  patchPlayer(data: object): Observable<any> {
    return this._api.patch('/player', data);
  }

  /**
   * PATCH /player/update_locale
   */
  playerUpdateLocale(query: object): Observable<any> {
    return this._api.patch('/player/update_locale', null, {
      params: query
    }).pipe(
      timeout(15000),
      catchError(() => {
        return of({language: 'en'});
      }),
    );
  }

  /**
   * GET: /player/reality_check
   */
  playerRealityCheck(): Observable<any> {
    return this._api.get('/player/reality_check');
  }

  /**
   * GET /current_ip
   */
  currentIp(params: object = {}): Observable<any> {
    return this._api.get('/current_ip', params).pipe(
      timeout(15000),
      catchError(() => {
        return of({
          country_code: null,
          default_currency: null,
          ip: null,
          region_code: null
        });
      })
    );
  }

  /**
   * GET /tournaments
   */
  tournamentsAll(params: object = {}): Observable<any> {
    return this._api.getCached(`/tournaments`, params);
  }

  /**
   * GET /tournaments/:id
   */
  tournaments(id: string, params: object = {}): Observable<any> {
    return this._api.getCached(`/tournaments/${id}`, params);
  }

  /**
   * GET /api/tournaments/:id/status
   */
  playerTournamentStatus(tournamentID) {
    return this._api.get(`/tournaments/${tournamentID}/status`);
  }

  /**
   * GET /player/documents
   */
  playerDocuments(): Observable<any> {
    return this._api.get('/player/documents');
  }

  /**
   * POST /player/documents
   */
  uploadPlayerDocuments(data: object, params: object = {}): Observable<any> {
    return this._api.post('/player/documents', data, {
      withCredential: true,
      headers: new HttpHeaders({})
    });
  }

  /**
   * DELETE /player/documents/:id
   *
   * @param id
   * @param data
   */
  deletePlayerDocument(id: string, data: object = {}): Observable<any> {
    return this._api.delete(`/player/documents/${id}`, data);
  }

  /**
   * PATCH /player/documents/:id
   *
   * @param id
   * @param data
   */
  updatePlayerDocument(id: string, data: object = {}): Observable<any> {
    return this._api.patch(`/player/documents/${id}`, data);
  }

  /**
   * POST /player/groups
   *
   * @param data
   */
  postPlayerGroups(data: object): Observable<any> {
    return this._api.post('/player/groups', data);
  }

  /**
   * GET /user_limits
   */
  userLimits(): Observable<any> {
    return this._api.get('/user_limits');
  }

  /**
   * POST /user_limits/confirm
   *
   * @param data
   */
  userLimitsConfirm(data: object): Observable<any> {
    return this._api.post('/user_limits/confirm', data);
  }

  /**
   * POST /user_limits
   *
   * @param data
   */
  postUserLimits(data: object): Observable<any> {
    return this._api.post('/user_limits', data);
  }

  /**
   * DELETE /user_limits/:id
   *
   * @param id
   */
  deleteUserLimit(id: string): Observable<any> {
    return this._api.delete(`/user_limits/${id}`);
  }

  /**
   * GET /player/payments
   *
   * @param params
   */
  playerPayments(params: object = {}): Observable<any> {
    return this._api.get('/player/payments', params);
  }

  /**
   * POST /player/payments/:id/recall
   *
   * @param id
   */
  playerPaymentsRecall(id: string): Observable<any> {
    return this._api.post(`/player/payments/${id}/recall`);
  }

  /**
   * GET /player/games
   */
  playerGames(): Observable<any> {
    return this._api.get('/player/games');
  }

  /**
   * GET /player/settings
   */
  playerSettings(): Observable<any> {
    return this._api.get('/player/settings');
  }

  /**
   * PATCH /player/update_bonus_settings
   *
   * @param data
   */
  playerUpdateBonusSettings(data: object = {}): Observable<any> {
    return this._api.patch('/player/update_bonus_settings', data);
  }

  /**
   * DELETE /player/clean_bonus_code
   */
  playerClearBonusCode(): Observable<any> {
    return this._api.delete('/player/clear_bonus_code');
  }

  /**
   * PATCH /player/set_bonus_code
   *
   * @param data
   */
  playerSetBonusCode(data: object = {}): Observable<any> {
    return this._api.patch('/player/set_bonus_code', data);
  }

  /**
   * GET /player/bonuses
   */
  playerBonuses(): Observable<any> {
    return this._api.get('/player/bonuses');
  }

  /**
   * GET /player/freespins
   */
  playerFreeSpins(): Observable<any> {
    return this._api.get('/player/freespins');
  }

  /**
   * POST /player/bonuses/:id/activation
   *
   * @param id
   */
  playerBonusesActivation(id: string): Observable<any> {
    return this._api.post(`/player/bonuses/${id}/activation`);
  }

  /**
   * POST /player/freespins/:id/activation
   *
   * @param id
   */
  playerFreeSpinsActivation(id: string | number): Observable<any> {
    return this._api.post(`/player/freespins/${id}/activation`);
  }

  /**
   * DELETE /player/bonuses/:id
   *
   * @param id
   */
  playerBonusesCanceling(id: string): Observable<any> {
    return this._api.delete(`/player/bonuses/${id}`);
  }

  /**
   * DELETE /player/freespins/:id
   *
   * @param id
   */
  playerFreeSpinsCanceling(id: string | number): Observable<any> {
    return this._api.delete(`/player/freespins/${id}`);
  }

  /**
   * POST /bonuses/coupon
   *
   * @param data
   */
  bonusesCoupon(data: object = {}): Observable<any> {
    return this._api.post('/bonuses/coupon', data);
  }

  /**
   * GET /bonuses/deposit
   */
  bonusesDeposit(): Observable<any> {
    return this._api.get('/bonuses/deposit');
  }

  /**
   * GET /subscriptions
   */
  subscriptions(): Observable<any> {
    return this._api.get('/subscriptions');
  }

  /**
   * PUT /subscriptions
   *
   * @param data
   */
  putSubscriptions(data: object = {}): Observable<any> {
    return this._api.put('/subscriptions', data);
  }

  /**
   * GET /player/phone
   */
  playerPhone(): Observable<any> {
    return this._api.get('/player/phone');
  }

  /**
   * POST /player/phone
   *
   * @param data
   */
  postPlayerPhone(data: object): Observable<any> {
    return this._api.post('/player/phone', data);
  }

  /**
   * DELETE /player/phone
   */
  deletePlayerPhone(): Observable<any> {
    return this._api.delete('/player/phone');
  }

  /**
   * POST /player/phone/confirm
   *
   * @param data
   */
  playerPhoneConfirm(data: object): Observable<any> {
    return this._api.post('/player/phone/confirm', data);
  }

  /**
   * POST /player/phone/resend_verification_code
   */
  playerPhoneResendVerificationCode(): Observable<any> {
    return this._api.post('/player/phone/resend_verification_code');
  }

  /**
   * POST /player/accounts
   *
   * @param data
   */
  postPlayerAccounts(data: object): Observable<any> {
    return this._api.post('/player/accounts', data);
  }

  /**
   * GET /payments/payment_methods/:currency
   *
   * @param currency
   */
  paymentMethodsByCurrency(currency: string): Observable<any> {
    return this._api.get(`/payments/payment_methods/${currency}`);
  }

  /**
   * POST /payments/payment_methods/:currency/:paymentId/:action + _info
   *
   * @param currency
   * @param paymentId
   * @param action
   */
  paymentMethodsByCurrencyAndId(currency: string, paymentId: string, action: string, data: object = {}): Observable<any> {
    return this._api.post(`/payments/payment_methods/${currency}/${paymentId}/${action}_info`, data);
  }

  /** POST /auth_providers/confirm
   *
   * @param data
   */
  authProvidersConfirmation(data: object): Observable<any> {
    return this._api.post('/auth_providers/confirm', data);
  }

  /**
   * POST /auth_providers/update_details
   *
   * @param data
   */
  authProvidersUpdateDetails(data: object): Observable<any> {
    return this._api.post('/auth_providers/update_details', data);
  }

  /**
   * GET /player/comp_points
   */
  playerCompPoints(): Observable<any> {
    return this._api.get('/player/comp_points');
  }

  /**
   * GET /comp_points/rates/money
   */
  compPointsRatesMoney(): Observable<any> {
    return this._api.get('/comp_points/rates/money');
  }

  /**
   * POST /comp_points/exchange/money
   *
   * @param data
   */
  compPointsExchangeMoney(data: object): Observable<any> {
    return this._api.post('/comp_points/exchange/money', data);
  }

  /**
   * GET /info/statuses
   */
  infoStatuses(): Observable<any> {
    return this._api.get('/info/statuses');
  }

  /**
   * GET /player/status_groups
   */
  playerStatusGroups(): Observable<any> {
    return this._api.get('/player/status_groups');
  }

  /**
   * GET /info/locales
   *
   * @param params
   */
  infoLocales(params: object = {}): Observable<any> {
    return this._api.get('/info/locales', params);
  }

  /**
   * GET /stats/winners/top/
   */
  statsWinnersTop(): Observable<any> {
    return this._api.get('/stats/winners/top/');
  }

  /**
   * GET /stats/winners/latest/
   */
  statsWinnersLatest(): Observable<any> {
    return this._api.get('/stats/winners/latest/');
  }

  /**
   * GET /player/favorite_games
   */
  playerFavoriteGames(): Observable<any> {
    return this._api.get(`/player/favorite_games`);
  }

  /**
   * PUT /player/favorite_games/:id
   *
   * @param id
   * @param data
   */
  putPlayerFavoriteGames(id: string, data: object = {}): Observable<any> {
    return this._api.put(`/player/favorite_games/${id}`, data);
  }

  /**
   * DELETE /player/favorite_games/:id
   *
   * @param id
   * @param data
   */
  deletePlayerFavoriteGames(id: string, data: object = {}): Observable<any> {
    return this._api.delete(`/player/favorite_games/${id}`, data);
  }

  /**
   * POST: /send_contact_message
   *
   * @param data
   */
  sendContactMessage(data: object): Observable<any> {
    return this._api.post('/send_contact_message', data);
  }

  /**
   * GET /player/played_games
   */
  playerPlayedGames(): Observable<any> {
    return this._api.get('/player/played_games' );
  }



  /**
   * GET /player/two_factor
   */
  getTwoFactorFields(): Observable<any> {
    return this._api.get('/player/two_factor');
  }

  /**
   * POST /player/two_factor
   */
  enable2FactorAuth(twoFAAuthData: {two_factor: {authentication_code: string, otp_secret: string}}): Observable<any> {
    return this._api.post('/player/two_factor', twoFAAuthData)
  }

  /**
   * DELETE /player/two_factor
   */
  disable2FactorAuth(twoFAAuthData: {two_factor: {authentication_code: string}}): Observable<any> {
    return this._api.delete('/player/two_factor', twoFAAuthData)
  }

  /**
   * GET /player/sessions
   */
  playerSessions(): Observable<any> {
    return this._api.get('/player/sessions')
  }

  /**
   * GET /info/player_fields
   */
  infoPlayerFields(): Observable<any> {
    return this._api.get('/info/player_fields').pipe(
      timeout(15000),
      catchError(() => {
        return of({
          contexts: {},
          fields: []
        });
      }),
    );
  }

  /**
   * GET /player/lootboxes
   */
  playerLootbox(): Observable<any> {
    return this._api.get('/player/lootboxes');
  }

  /**
   * POST: /player/lootboxes/:issue_id/activation
   * @param id
   */
  playerLootboxActivate(id): Observable<any> {
    return this._api.post(`/player/lootboxes/${id}/activation`, {});
  }

  /**
   * DELETE /player/lootboxes/:issue_id
   * @param id
   */
  playerLootboxDelete(id): Observable<any> {
    return this._api.delete(`/player/lootboxes/${id}`);
  }

  /**
   * GET /jackpots
   */
  jackpots(): Observable<any[]> {
    return this._api.get('/jackpots');
  }

  /**
   * GET /jackpot_wins
   */
  jackpotWins(params: object = {}): Observable<any[]> {
    return this._api.get('/jackpot_wins', params);
  }

  /**
   * Get /games/total_jackpots
   */
  getGamesTotalJackpots(): Observable<any> {
    return this._api.get('/games/total_jackpots');
  }
}
