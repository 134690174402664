import { Injectable } from '@angular/core';
import { ApiProvider } from './helpers/api-provider';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../language/language.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {DeviceDetectorService} from 'ngx-unificator/services';

@Injectable({
  providedIn: 'root'
})
export class CmsApiService {

  /**
   * For API communication with lang segment
   */
  private _api: ApiProvider = new ApiProvider(
    {
      defaultOptions: {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'DEVICE-TYPE': this._device.isDesktop() ? 'Desktop' : this._device.isTablet() ? 'Tablet' : 'Mobile'

        }),
        withCredentials: true
      },
      host: environment.api_host,
      urlResolutionFn: () => this._lang.langChange$.pipe(
        map(this._lang.normalizeLangForApi),
        map(lang => '/' + lang)
      )
    }
  );

  /**
   * For API communication without lang segment
   */
  private _noLangApi: ApiProvider = new ApiProvider({
      defaultOptions: {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'DEVICE-TYPE': this._device.isDesktop() ? 'Desktop' : this._device.isTablet() ? 'Tablet' : 'Mobile'

        }),
        withCredentials: true
      },
      host: environment.api_host
    }
  );

  /**
   * For API communication without lang segment
   */
  private _errorApi: ApiProvider = new ApiProvider({
    defaultOptions: {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'DEVICE-TYPE': this._device.isDesktop() ? 'Desktop' : this._device.isTablet() ? 'Tablet' : 'Mobile'

      }),
      withCredentials: true
    },
    host: environment.error_host + '/api'
  });

  public get customHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  constructor(
    private _lang: LanguageService,
    private _device: DeviceDetectorService
  ) { }

  public get customHeadersList(): any[] {
    return this._api.customHeadersList;
  }

  public get customNoLangHeadersList(): any[] {
    return this._noLangApi.customHeadersList;
  }

  /**
   * /banner/list
   *
   * @param params
   */
  bannerList(params: object = {}): Observable<any> {
    return this._api.getCached('/banner/list', params);
  }

  /**
   * /banner/item
   *
   * @param params
   */
  bannerItem(params: object = {}): Observable<any> {
    return this._api.getCached('/banner/item', params);
  }

  /**
   * /page/item
   *
   * @param params
   */
  pageItem(params: object = {}): Observable<any> {
    return this._api.getCached('/page/item', params);
  }

  /**
   * /payment/list
   *
   * @param params
   */
  paymentList(params: object = {}): Observable<any> {
    return this._api.getCached('/payment/list', params);
  }

  /**
   * /promotion/item
   *
   * @param params
   */
  promotionItem(params: object = {}): Observable<any> {
    return this._api.getCached('/promotion/item', params);
  }

  /**
   * /promotion/list
   *
   * @param params
   */
  promotionList(params: object = {}): Observable<any> {
    return this._api.getCached('/promotion/list', params);
  }

  /**
   * /game/provider/list
   *
   * @param params
   */
  gameProviderList(params: object = {}): Observable<any> {
    return this._api.getCached('/game/provider/list', params);
  }

  /**
   * /game/category/list
   *
   * @param params
   */
  gameCategoryAttrList(params: object = {}): Observable<any> {
    return this._api.getCached('/game/category/attribute/list', params);
  }

  /**
   * /tournament/item
   *
   * @param params
   */
  tournamentItem(params: object = {}): Observable<any> {
    return this._api.getCached('/tournament/item', params);
  }

  /**
   * /tournament/list
   *
   * @param params
   */
  tournamentList(params: object = {}): Observable<any> {
    return this._api.getCached('/tournament/list', params);
  }

  /**
   * /page/menu
   *
   * @param params
   */
  pageMenu(params: object = {}): Observable<any> {
    return this._api.getCached('/page/menu', params);
  }

  /**
   * /page/privacy
   *
   * @param params
   */
  pagePrivacy(params: object = {}): Observable<any> {
    return this._api.getCached('/page/privacy', params);
  }

  /**
   * /page/terms
   *
   * @param params
   */
  pageTerms(params: object = {}): Observable<any> {
    return this._api.getCached('/page/terms', params);
  }

  /**
   * /static-content/item
   *
   * @param params
   */
  staticContentItem(params: object = {}): Observable<any> {
    return this._api.getCached('/static-content/item', params);
  }

  /**
   * /static-content/list
   *
   * @param params
   */
  staticContentList(params: object = {}): Observable<any> {
    return this._api.getCached('/static-content/list', params);
  }

  /**
   * /game/list
   *
   * @param params
   */
  gameList(params: object = {}): Observable<any> {
    return this._api.getCached('/game/list', params);
  }

  /**
   * /game/content
   *
   * @param params
   */
  gameContent(params: object = {}): Observable<any> {
    return this._api.getCached('/game/content/item', params);
  }

  /**
   * /game/list
   *
   * @param params
   */
  gameListNoCache(params: object = {}): Observable<any> {
    return this._api.get('/game/list', params);
  }

  /**
   * /currency/list/ss
   *
   * @param params
   */
  currencyListSS(params: object = {}): Observable<any> {
    return this._api.getCached('/currency/list/ss', params);
  }

  /**
   * /country/list
   *
   * @param params
   */
  countryList(params: object = {}): Observable<any> {
    return this._api.getCached('/country/list', params);
  }

  /**
   * /service/environment
   *
   * @param params
   */
  serviceEnvironment(params: object = {}): Observable<any> {
    return this._noLangApi.get('/service/environment');
  }

  /**
   * /service/io
   * @param data
   * @param options
   */
  serviceIo(data: object = {}, options: any): Observable<any> {
    return this._noLangApi.post('/service/io', data, options);
  }

  /**
   * /service/abtest
   *
   * @param params
   */
  loadABTest(params: object = {}, options: object = {}): Observable<any> {
    return this._noLangApi.get('/service/abtest', params, options);
  }

  /**
   * /translate/list
   *
   * @param params
   */
  translateList(params: object = {}): Observable<any> {
    return this._api.getCached('/translate/list', params);
  }

  /**
   * /localization/list
   *
   * @param params
   */
  localizationList(params: object = {}): Observable<any> {
    return this._api.getCached('/localization/list', params);
  }

  /**
   * /sys-notification/item
   *
   * @param params
   */
  sysNotificationItem(params: object = {}): Observable<any> {
    return this._api.getCached('/sys-notification/item', params);
  }

  /**
   * /sys-notification/list
   *
   * @param params
   */
  sysNotificationList(params: object = {}): Observable<any> {
    return this._api.getCached('/sys-notification/list', params);
  }

  /**
   * /news/list
   *
   * @param params
   */
  newsList(params: object = {}): Observable<any> {
    return this._api.getCached('/news/list', params);
  }

  /**
   * /news/item
   *
   * @param params
   */
  newsItem(params: object = {}): Observable<any> {
    return this._api.getCached('/news/item', params);
  }

  /**
   * /page/badge/list
   *
   * @param params
   */
  pageBadgeList(params: object = {}, options: object = {}): Observable<any> {
    return this._api.get('/page/badge/list', params, options);
  }

  /**
   * /game/badge/list
   *
   * @param params
   */
  gameBadgeList(params: object = {}, options: object = {}): Observable<any> {
    return this._api.get('/game/badge/list', params, options);
  }

  /**
   * /tournament/badge/list
   *
   * @param params
   */
  tournamentBadgeList(params: object = {}, options: object = {}): Observable<any> {
    return this._api.get('/tournament/badge/list', params, options);
  }

  /**
   * /promotion/badge/list
   *
   * @param params
   */
  promotionBadgeList(params: object = {}, options: object = {}): Observable<any> {
    return this._api.get('/promotion/badge/list', params, options);
  }

  /**
   * /page/badge/item
   *
   * @param params
   */
  pageBadgeItem(params: object = {}, options: object = {}): Observable<any> {
    return this._api.get('/page/badge/item', params, options);
  }

  /**
   * POST /counter/:slugList
   *
   * @param slug
   * @param data
   * @param options
   */
  postListEntityOpen(slugList: string, data: object = {}, options: object = {}): Observable<any> {
    return this._api.post(`/counter/${slugList}`, data, options);
  }

  /**
   * POST /counter/:slugItem
   *
   * @param slug
   * @param data
   * @param options
   */
  postItemEntityOpen(slugItem: string, data: object = {}, options: object = {}): Observable<any> {
    return this._api.post(`/counter/${slugItem}`, data, options);
  }

  /**
   * POST /counter/game-open
   *
   * @param params
   */
  postGameOpen(data: object = {}, params: object = {}) {
    return this._api.post('/counter/game-open', data, {
      params
    });
  }

  /**
   * POST /account/favorite
   *
   * @param params
   */
  postAccountFavorite(data: object = {}, params: object = {}) {
    return this._api.post('/account/favorite', data, {params});
  }

  /**
   * DELETE /account/favorite
   *
   * @param params
   */
  deleteAccountFavorite(data: object = {}, params: object = {}) {
    return this._api.delete('/account/favorite', data, {params});
  }

  /**
   * GET /exchange/rate/crypto
   *
   * @param params
   */
  public getExchangeRate(params: object = {}): Observable<any> {
    return this._api.getCached(`/exchange/rate/crypto`, params);
  }

  /**
   * POST /bonus/check
   * @param data
   * @param params
   */
  postBonusCheck(data: string, params: object = {}): Observable<any> {
    return this._noLangApi.post('/bonus/check', data, {params});
  }

  /**
   * POST /ss/player/love/mail/send
   * @param data
   * @param params
   */
  loveMailSend(data: object, params: object = {}): Observable<any> {
    return this._noLangApi.post('/ss/player/love/mail/send', data, {params});
  }

  /**
   * GET /service/cache/cf
   * @param params
   * @param options
   */
  getCacheCf(params: object = {}, options: object = {}): Observable<any> {
    return this._noLangApi.get('/service/cache/cf', params, options);
  }


  /**
   * GET /leaderboard/list
   *
   * @param params
   */
  getLeaderBoard(params: object = {}): Observable<any> {
    return this._api.getCached('/leaderboard/list', params);
  }


  /**
   * POST /ss/player/promo/file/send
   * @param data
   * @param params
   */
  postPlayerPromoFileSendValentine(data: object = {}, params: object = {}): Observable<any> {
    return this._noLangApi.post('/ss/player/promo/file/send?type=valentine', data, {
      params
    })
  }


    /**
   * Generate qr
   * @param params
   */
  generateQr(params: object = {}): Observable<any> {
    return this._errorApi.get('/qr/generate', params);
  }
}
