import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { SsApiService } from '../api/ss-api.service';
import { CookieService } from 'ngx-unificator/services';
import { PlatformService } from '../platform.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  /**
   * Current language
   */
  private _currentLanguage;

  /**
   * Emits when lang changed
   */
  private _langChange$: ReplaySubject<string> = new ReplaySubject<string>(1);

  /**
   * Initial language
   */
  private readonly INITIAL_LANG: string = 'en';

  /**
   * Previous used language
   */
  private _prevLanguage: string = this.INITIAL_LANG;

  constructor(
    private _api: SsApiService,
    private _cookie: CookieService,
    private _platform: PlatformService
  ) {

  }

  /**
   * Access to previous language
   */
  get previous(): string {
    return this._prevLanguage;
  }

  /**
   * Access to current language from outside
   */
  get current(): string {
    return this._currentLanguage;
  }

  /**
   * Access to _langChange$ from outside
   */
  get langChange$(): ReplaySubject<string> {
    return this._langChange$;
  }

  /**
   * Change website lang to provided
   *
   * @param lang
   */
  changeLang(lang: string) {
    if (lang !== this._currentLanguage) {
      this._updateLocale(lang);
      this._prevLanguage = this._currentLanguage;
      this._currentLanguage = lang;
      this._langChange$.next(this._currentLanguage);
      this._cookie.set('lang', lang, 99, '/');
    }
  }

  /**
   * Returns auto detected user lang
   *
   * @private
   */
  public detectUserLang(routeLang: string, envLang: string, langList: any[]): string {
    const browserLang = this._platform.isBrowser ? navigator.languages.find(lang => this._langExists(lang.toLowerCase(), langList)) : null;
    const cookieLang = this._cookie.get('lang');

    let definedLang = this.INITIAL_LANG;

    if (browserLang) {
      definedLang = browserLang.toLowerCase();
    } else if (envLang && this._langExists(envLang, langList)) {
      definedLang = envLang;
    }

    if (cookieLang && this._langExists(cookieLang, langList)) {
      definedLang = cookieLang;
    }

    if (routeLang && this._langExists(routeLang, langList)) {
      definedLang = routeLang;
    }

    return definedLang;
  }

  /**
   * Send update_locale request
   *
   * @private
   */
  private _updateLocale(locale: string) {
    const parts = locale.split('-');

    if(parts[1]) parts[1] = parts[1].toUpperCase();

    // @ts-ignore
    this._api.playerUpdateLocale( {
      locale: parts.join('-')
    }).subscribe();
  }

  /**
   * Is provided lang available
   *
   * @param lang
   * @param list
   * @private
   */
  private _langExists(lang: string, list: {short: string}[]): boolean {
    return list.some(available => available.short === lang);
  }

  /**
   * Get normalize lang
   * @param lang
   */
  public normalizeLang(lang: string) {
    switch (lang) {
      case 'ce':
        return 'en-ca';
      case 'nz':
        return 'en-nz';
      case 'au':
        return 'en-au';
      default:
        return lang;
    }
  }

  /**
   * Get normalize lang for lang
   * @param lang
   */
  public normalizeLangForApi(lang: string) {
    switch (lang) {
      case 'en-ca':
        return 'ce';
      case 'en-nz':
        return 'nz';
      case 'en-au':
        return 'au';
      default:
        return lang;
    }
  }
}
